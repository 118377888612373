<template>
  <div class="container">
    <section class="section">
      <p>
        <strong>Terms of Service Lion Parcel Consumer App</strong>
      </p>
      <h4>I.&nbsp;DEFINISI</h4>
      <ul>
        <li>
          &ldquo;Aplikasi&rdquo; adalah aplikasi Lion Parcel, yakni aplikasi
          jasa ekspedisi berbasis Android &amp; iOS yang dibuat oleh PT Lion
          Express.
        </li>
        <li>
          &ldquo;Lion Parcel&rdquo; adalah pengelola Aplikasi, yakni PT Lion
          Express .
        </li>
        <li>
          &ldquo;Pengguna (Tamu)&rdquo; adalah pihak yang menggunakan aplikasi
          Lion Parcel, namun belum melakukan registrasi atau belum menyelesaikan
          proses registrasi hingga menyelesaikan proses verifikasi OTP.
        </li>
        <li>
          &ldquo;Pengguna (Terdaftar)&rdquo; adalah pihak yang menggunakan
          aplikasi Lion Parcel, dan sudah melakukan registrasi dan berhasil
          melakukan verifikasi dengan menggunakan OTP.
        </li>
        <li>
          &ldquo;Akun&rdquo; adalah informasi pendaftaran Pengguna (Terdaftar)
          yang terdiri dari nomor telepon dan password.
        </li>
        <li>
          &ldquo;Pengirim&rdquo; adalah Pengguna (Terdaftar) yang melakukan
          proses pengiriman paket melalui aplikasi.
        </li>
        <li>
          &ldquo;Pemberi Paket&rdquo; adalah pihak yang mewakilkan Pengirim
          untuk menyerahkan paket kepada Kurir, dalam hal Pengirim berhalangan
          untuk menyerahkan Paket secara langsung kepada Kurir. Pemberi Paket
          dapat bertindak sebagai Pengirim.
        </li>
        <li>
          &ldquo;Penerima Akhir&rdquo; adalah pihak penerima akhir Paket yang
          dikirimkan oleh Pengirim. Penerima Akhir dapat bertindak sebagai
          Penerima Paket.
        </li>
        <li>
          &ldquo;Penerima Paket&rdquo; adalah pihak yang menerima paket pertama
          kali (di alamat tujuan) sebelum diteruskan kepada Penerima Akhir.
        </li>
        <li>
          &ldquo;Paket&rdquo; adalah satuan barang yang dikirimkan oleh Pengguna
          (Terdaftar).
        </li>
        <li>
          &ldquo;Kurir&rdquo; adalah pihak Lion Parcel yang bertugas melakukan
          penjemputan Paket pada lokasi Pick-Up.
        </li>
        <li>
          &ldquo;Pick-Up&rdquo; adalah proses penjemputan Paket oleh Kurir untuk
          diantarkanmenuju Origin Hub.
        </li>
        <li>
          &ldquo;Origin Hub&rdquo; adalah fasilitas Lion Parcel untuk melakukan
          penyortiran dan pemrosesan Paket terdekat dengan lokasi Pemberi Paket.
        </li>
        <li>
          &ldquo;Destination Hub&rdquo; adalah fasilitas Lion Parcel untuk
          melakukan penyortiran dan pemrosesan Paket terdekat dengan lokasi
          Penerima Akhir.
        </li>
        <li>
          &ldquo;Delivery&rdquo; adalah proses pengantaranPaket oleh Kurir dari
          Hub menuju penerima.
        </li>
        <li>
          &ldquo;STT&rdquo;/&rdquo;Surat Tanda Terima&rdquo; adalah nomor bukti
          input Paket dalam aplikasi Elexys
        </li>
        <li>
          &ldquo;Outstanding&rdquo; adalah tagihan yang belum dibayarkan oleh
          Pengirim kepada Lion Parcel.
        </li>
      </ul>
      <h4>II.&nbsp;UMUM</h4>
      <p>
        Peraturan Umum beserta Syarat dan Ketentuan ini dapat diubah
        sewaktu-waktu tanpa pemberitahuan terlebih dahulu. Keputusan yang
        diambil berdasarkan peraturan ini bersifat mutlak dan tidak dapat
        diganggu gugat.
      </p>
      <h4>III.&nbsp;REGISTRASI &amp; AKUN</h4>
      <ol>
        <li>
          Pengguna (Tamu) dapat mendaftarkan diri dengan melakukan registrasi
          dalam aplikasi Lion Parcel, dengan mengisi nomor telepon dan password.
          Setelah itu Pengguna (Tamu) diminta untuk menyelesaikan verifikasi
          dengan memasukkan OTP yang dikirimkan melalui SMS. Setelah proses
          berhasil, maka Pengguna (Tamu) akan berubah statusnya menjadi Pengguna
          (Terdaftar). Satu nomor telepon hanya bisa digunakan untuk satu akun.
        </li>
        <li>
          Pengguna (Terdaftar) dapat melakukan perubahan informasi Akun melalui
          menu dalam aplikasi Lion Parcel.
        </li>
        <li>
          Pengguna (Terdaftar) wajib untuk menjaga kerahasiaan Akun pribadinya.
          Lion Parcel tidak bertanggung jawab atas kerugian atau kerusakan yang
          timbul dari penyalahgunaan Akun.
        </li>
        <li>
          Lion Parcel memiliki kewenangan untuk menindak segala dugaan
          pelanggaran atau pelanggaran Syarat &amp; Ketentuan berupa pemblokiran
          akun atau penghapusan akun tanpa pemberitahuan terlebih dahulu kepada
          Pengguna.
        </li>
        <li>
          Pengguna dilarang menciptakan dan/atau menggunakan perangkat,
          software, fitur dan/atau alat lainnya yang bertujuan untuk
          memanipulasi Aplikasi.
        </li>
        <li>
          Lion Parcel memiliki kewenangan untuk melakukan langkah/tindakan hukum
          bilamana pengguna bertujuan untuk memanipulasi Aplikasi sesuai UU No.
          11 Tahun 2008 tentang Informasi dan Transaksi Elektronik.
        </li>
      </ol>
      <hr />

      <p>
        <strong>Terms of Service Lion Parcel Driver App</strong>
      </p>
      <h4>I.&nbsp;DEFINISI</h4>
      <ul>
        <li>
          &ldquo;Aplikasi&rdquo; adalah aplikasi Lion Parcel yang digunakan oleh
          kurir untuk melakukan pemrosesan paket Lion Parcel. Aplikasi hanya
          tersedia dalam versi Android.
        </li>
        <li>
          &ldquo;Lion Parcel&rdquo; adalah pengelola Aplikasi, yakni PT Lion
          Express.
        </li>
        <li>
          &ldquo;Akun&rdquo; adalah informasi pendaftaran Pengguna (Terdaftar)
          yang terdiri dari nomor telepon dan password.
        </li>
        <li>
          &ldquo;Pengguna&rdquo; adalah pihak yang mengunduh aplikasi dari
          Google Play Store.
        </li>
        <li>
          &ldquo;Pengirim&rdquo; adalah Pengguna (Terdaftar) yang melakukan
          proses pengiriman paket melalui aplikasi.
        </li>
        <li>
          &ldquo;Pemberi Paket&rdquo; adalah pihak yang mewakilkan Pengirim
          untuk menyerahkan paket kepada Kurir, dalam hal Pengirim berhalangan
          untuk menyerahkan Paket secara langsung kepada Kurir. Pemberi Paket
          dapat bertindak sebagai Pengirim.
        </li>
        <li>
          &ldquo;Penerima Akhir&rdquo; adalah pihak penerima akhir Paket yang
          dikirimkan oleh Pengirim. Penerima Akhir dapat bertindak sebagai
          Penerima Paket.
        </li>
        <li>
          &ldquo;Penerima Paket&rdquo; adalah pihak yang menerima paket pertama
          kali (di alamat tujuan) sebelum diteruskan kepada Penerima Akhir.
        </li>
        <li>
          &ldquo;Paket&rdquo; adalah satuan barang yang dikirimkan oleh Pengguna
          (Terdaftar).
        </li>
        <li>
          &ldquo;Kurir&rdquo; adalah pihak Lion Parcel yang bertugas melakukan
          penjemputan Paket pada lokasi Pick-Up.
        </li>
        <li>
          &ldquo;Origin Hub&rdquo; adalah fasilitas Lion Parcel untuk melakukan
          penyortiran dan pemrosesan Paket terdekat dengan lokasi Pemberi Paket.
        </li>
        <li>
          &ldquo;Destination Hub&rdquo; adalah fasilitas Lion Parcel untuk
          melakukan penyortiran dan pemrosesan Paket terdekat dengan lokasi
          Penerima Akhir.
        </li>
        <li>
          &ldquo;Pick-Up&rdquo; adalah proses penjemputan Paket oleh Kurir pada
          titik penjemputan paket.
        </li>
        <li>
          &ldquo;Delivery&rdquo; adalah proses pengantaran Paket oleh Kurir dari
          Destination Hub menuju penerima akhir.
        </li>
        <li>
          &ldquo;Multiple Delivery&rdquo; adalah proses konfirmasi delivery
          Paket sekaligus ke penerima akhir.
        </li>
        <li>
          &ldquo;Drop Off&rdquo; adalah proses pengantaran paket oleh kurir dari
          titik Pickup menuju Origin Hub.
        </li>
        <li>
          &ldquo;STT&rdquo;/&rdquo;Surat Tanda Terima&rdquo; adalah nomor bukti
          input Paket dalam aplikasi Elexys.
        </li>
        <li>
          &ldquo;Task List&rdquo; adalah daftar paket yang perlu untuk di
          Pick-Up dari Pengirim atau Delivery menuju Penerima Akhir. Task List
          dapat diakses oleh kurir pada halaman setelah kurir berhasil login.
        </li>
      </ul>
      <h4>II.&nbsp;UMUM</h4>
      <ol>
        <li>
          Peraturan Umum beserta Syarat dan Ketentuan ini dapat diubah
          sewaktu-waktu tanpa pemberitahuan terlebih dahulu. Keputusan yang
          diambil berdasarkan peraturan ini bersifat mutlak dan tidak dapat
          diganggu gugat.
        </li>
        <li>
          Kurir wajib untuk memastikan bahwa handphone dalam kondisi aktif,
          memiliki paket internet yang berjalan dan memiliki GPS yang aktif.
        </li>
      </ol>
      <h4>III.&nbsp;REGISTRASI &amp; AKUN</h4>
      <ol>
        <li>
          Untuk menggunakan aplikasi ini, pengguna harus mendaftarkan diri
          menjadi kurir Lion Parcel, kemudian Lion Parcel akan memberikan
          informasi akun kepada pengguna.
        </li>
        <li>
          Kurir memiliki kewajiban untuk menjaga kerahasiaan akun. Lion Parcel
          tidak bertanggung jawab atas kerugian atau kerusakan yang timbul dari
          penyalahgunaan Akun.
        </li>
        <li>
          Kurir dapat melakukan perubahan akun melalui menu yang terdapat dalam
          aplikasi.
        </li>
        <li>Satu nomor telepon hanya bisa digunakan untuk satu akun.</li>
        <li>
          Pengguna (Terdaftar) wajib untuk menjaga kerahasiaan Akun pribadinya.
        </li>
        <li>
          Lion Parcel memiliki kewenangan untuk menindak segala dugaan
          pelanggaran atau pelanggaran Syarat &amp; Ketentuan berupa pemblokiran
          akun atau penghapusan akun tanpa pemberitahuan terlebih dahulu kepada
          Kurir.
        </li>
        <li>
          Pengguna/Kurir dilarang menciptakan dan/atau menggunakan perangkat,
          software, fitur dan/atau alat lainnya yang bertujuan untuk
          memanipulasi Aplikasi.
        </li>
        <li>
          Lion Parcel memiliki Kewenangan untuk melakukan segala tindakan tidak
          terbatas pada hingga pemutusan hubungan kerja bilamana kurir bertujuan
          dan terbukti untuk memanipulasi Aplikasi.
        </li>
      </ol>
      <h4>IV.&nbsp;KETENTUAN DALAM PICK-UP</h4>
      <ol>
        <li>
          Kurir akan mendapatkan notifikasi melalui aplikasi apabila kurir
          memiliki permintaan pickup. Kurir berhak untuk menerima maupun menolak
          permintaan pickup.
          <ol>
            <li>
              Dalam hal kurir menerima permintaan pickup, maka permintaan pickup
              yang sudah diterima akan ditambahkan task list.
            </li>
            <li>
              Dalam hal kurir menolak permintaan pickup, maka kurir wajib untuk
              menjelaskan alasan penolakan pickup.
            </li>
          </ol>
        </li>
        <li>
          Selama perjalanan menuju titik pickup, kurir tidak akan mendapatkan
          permintaan pickup baru.
        </li>
        <li>
          Kurir dapat menelepon dan mengirimkan pesan ke pengirim maupun melihat
          titik pickup melalui aplikasi.
        </li>
        <li>
          Kurir dapat membatalkan pickup apabila pemberi paket atau pengirim
          tidak ada. Maka kurir diwajibkan untuk mengisi alasan pembatalan
          pickup.
        </li>
        <li>
          Apabila kurir sudah sampai ke titik penjemputan, maka kurir wajib
          mengkonfirmasi proses pickup dengan melakukan swipe/tap pada task
          list, kemudian kurir wajib mengisi detail paket yang di-pickup antara
          lain : nama pemberi paket, relasi, foto paket, jumlah paket dan
          tandatangan oleh pemberi paket. Beberapa hal yang perlu diperhatikan :
          <ol>
            <li>
              Kurir wajib mengisi identitas dengan tepat, guna pelacakan paket
              di kemudian hari.
            </li>
            <li>
              Kurir wajib menghitung ulang jumlah paket yang diberikan kepada
              kurir saat proses pickup, dan memasukkan jumlah paket pada
              aplikasi dengan tepat
            </li>
            <li>
              Kurir wajib menuliskan shipment ID yang muncul di aplikasi pada
              setiap paket
            </li>
            <li>
              Setelah proses ini berhasil, maka kurir dapat kembali mendapatkan
              permintaan pickup baru apabila ada
            </li>
          </ol>
        </li>
        <li>
          Paket yang sudah di-pickup, wajib diantarkan menuju Origin Hub
          sesegera mungkin pada hari yang sama, kemudian melakukan konfirmasi
          Drop-Off ke Origin Hub. Sebelum melakukan proses tersebut, kurir wajib
          menghitung kembali jumlah paket per shipment ID, dan mengupdate jumlah
          paket per shipment ID pada layar Drop-Off, kemudian kurir wajib
          menginputkan nama penerima paket di Origin Hub.
        </li>
        <li>
          Dalam hal terjadi ketidakcocokan antara jumlah paket yang di-pickup
          dan jumlah paket yang di di-drop off, kurir wajib memasukkan alasan
          kehilangan paket
        </li>
      </ol>
      <h4>V.&nbsp;KETENTUAN DALAM DELIVERY</h4>
      <ol>
        <li>
          Paket yang sudah sampai di Destination Hub, wajib diambil oleh kurir,
          dengan cara :
          <ol>
            <li>
              Kurir yang berada di Hub melakukan scan barcode/menginput manual
              STT pada paket yang sudah berada pada Destination Hub.
            </li>
            <li>
              Apabila scan/input berhasil, maka task list akan terupdate dengan
              paket yang harus di deliver ke penerima akhir.
            </li>
          </ol>
        </li>
        <li>
          Paket yang sudah dibawa dan di scan/input melalui aplikasi, akan
          dibawa menuju penerima akhir. Sesampainya kurir di alamat penerima
          akhir, kurir wajib menyerahkan paket dan mengkonfirmasi delivery (POD)
          dengan memasukkan bukti berupa : nama penerima paket, relasi antara
          penerima paket dan penerima akhir, foto paket telah diterima dan
          tandatangan.
        </li>
        <li>
          Dalam hal tidak ada penerima paket pada alamat delivery, maka kurir
          wajib melaporkan paket sebagai DEX, serta mengisi alasan pembatalan
          delivery, dan membawa paket tersebut kembali ke Destination Hub untuk
          dapat dilakukan scan/input melalui aplikasi.
        </li>
        <li>
          Untuk memudahkan delivery, kurir dapat melakukan Multiple Delivery,
          dengan menyeleksi beberapa Task List, kemudian dilakukan pelaporan POD
          pada paket yang sudah dipilih.
        </li>
      </ol>
    </section>
  </div>
</template>

<script>
import MetaInfoUseCase from '@/app/metaApi/usecase/metaInfo.js';

export default {
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      metaInfo: '',
    };
  },
  created() {
    this.fetchMetaInfo();
  },
  methods: {
    fetchMetaInfo() {
      MetaInfoUseCase.getMeta('_app_tnc') // path tapi / dengan _ ex: /about jadi _about
        .then(resp => {
          if (!resp.err && resp.data) {
            this.metaInfo = resp.data;
          }
        })
        .catch(err => {});
    },
  },
};
</script>
