<template>
  <div class="container">
    <p>
      <strong>Kebijakan Privasi Lion Parcel</strong>
    </p>
    <p>Kebijakan Privasi ini menjelaskan bagaimana PT Lion Express, masing-masing anak perusahaan, afiliasinya, perusahaan-perusahaan yang terasosiasi dengannya, dan entitas-entitas yang dikendalikan bersama (secara kolektif disebut “Lion Parcel”, atau “kami”) mengumpulkan, menyimpan, menggunakan, mengolah, menganalisa, menguasai, mentransfer, mengungkapkan, menampilkan, membuka dan melindungi setiap data atau informasi pribadi anda (selanjutnya disebut “Pengguna”, atau “Anda”) yang diberikan kepada Lion Parcel terhadap penggunaan situs (www.lionparcel.com) dan aplikasi milik Lion Parcel (selanjutnya disebut “Aplikasi”) dalam penggunaan layanan yang kami sediakan (selanjutnya disebut “Layanan”).</p>
    <p>“Data Pribadi” adalah segala informasi yang dapat digunakan untuk mengidentifikasi Anda atau dari mana Anda dapat diidentifikasi. Ini termasuk tetapi tidak terbatas pada nama, kebangsaan, nomor telepon, rincian bank dan kartu kredit, keminatan pribadi (personal interest), alamat email, foto diri, nomor identifikasi yang dikeluarkan pemerintah, data biometrik, ras, tanggal lahir, status perkawinan, agama, maupun informasi perangkat dan informasi lainnya yang disediakan oleh Anda kepada Kami.</p>
    <p>Lion Parcel dengan serius bertanggung jawab berdasarkan peraturan yang berlaku di Indonesia tentang data privasi (selanjutnya disebut "Peraturan") dan berkomitmen untuk menghormati hak dan masalah privasi semua Pengguna. Kami mengakui pentingnya Data Pribadi yang telah Anda percayakan kepada kami dan merupakan tanggung jawab kami.</p>
    <ol type="1" class="olBold">
      <li>
        <h4>Pengumpulan Data Pribadi</h4>
        <p>Lion Parcel mengumpulkan Data Pribadi Pengguna dengan tujuan untuk memproses transaksi Pengguna, mengelola dan memperlancar proses penggunaan Aplikasi/Situs, serta tujuan-tujuan lainnya selama diizinkan oleh peraturan perundang-undangan yang berlaku. Adapun data Pengguna yang dikumpulkan adalah sebagai berikut:</p>
        <ol type="1">
          <li>
            Data Pribadi yang diserahkan secara mandiri oleh Pengguna, termasuk namun tidak terbatas pada data yang diserahkan pada saat Pengguna:
            <ol type="a">
              <li>Mendaftarkan akun di Lion Parcel, termasuk diantaranya nama, nomor handphone, dan password;</li>
              <li>Membuat pengiriman, termasuk diantaranya nama pengirim, alamat pengirim (Lokasi GPS), nomor handphone pengirim, nama penerima, alamat penerima, nomor handphone penerima;</li>
              <li>Melakukan pembayaran termasuk diantaranya Instant Payment, rekening Bank, nomor handphone dan kode pembayaran;</li>
              <li>Melakukan klaim termasuk diantaranya foto KTP, STNK, cover buku tabungan, invoice pembelian, bukti foto dan video unboxing;</li>
              <li>Melakukan tarik saldo diantaranya nama Bank, nama pemilik rekening dan nomor rekening;</li>
              <li>Mengisi survey yang dikirimkan oleh Lion Parcel atau atas nama Lion Parcel;</li>
              <li>Menghubungi Lion Parcel, termasuk layanan pelanggan.</li>
            </ol>
          </li>
          <li>
            Data Pribadi yang terekam saat Pengguna menggunakan Aplikasi atau Situs, termasuk namun tidak terbatas pada:
            <ol type="a">
              <li>Data lokasi riil atau perkiraannya seperti geo-location, dan sebagainya;</li>
              <li>Data berupa waktu dari setiap aktivitas Pengguna, termasuk aktivitas pendaftaran, login, transaksi, dan lain sebagainya;</li>
              <li>Data perangkat, diantaranya jenis perangkat yang digunakan untuk mengakses Aplikasi/Situs, termasuk model perangkat keras, sistem operasi dan versinya, perangkat lunak, nama file dan versinya, pilihan bahasa, pengenal perangkat unik, pengenal iklan, nomor seri, informasi gerakan perangkat, dan/atau informasi jaringan seluler;</li>
              <li>Data catatan (log), diantaranya catatan pada server yang menerima data seperti perangkat, tanggal dan waktu akses, fitur aplikasi atau laman yang dilihat, proses kerja aplikasi dan aktivitas sistem lainnya, jenis peramban (browser), perangkat aplikasi, dan/atau situs atau layanan pihak ketiga yang Pengguna gunakan sebelum berinteraksi dengan Aplikasi/Situs.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <h4>Penggunaan Data Pribadi</h4>
        <p>Lion Parcel dapat menggunakan data pribadi yang diperoleh dari Pengguna sebagaimana disebutkan pada bagian sebelumnya untuk hal-hal sebagai berikut:</p>
        <ol type="1">
          <li>
            Memproses segala bentuk permintaan, aktivitas maupun transaksi yang dilakukan oleh Pengguna melalui Aplikasi/Situs termasuk memproses pesanan Pengguna dan mengaktifkan atau menonaktifkan Layanan, memungkinkan kami untuk memproses tagihan dan pembayaran serta menginformasikan Pengguna mengenai peningkatan dan pembaruan Layanan.
          </li>
          <li>
            Penyediaan fitur-fitur untuk memberikan, mewujudkan, memelihara dan memperbaiki Layanan kami, termasuk:
            <ol type="a">
              <li>Mengaktifkan fitur yang mempersonalisasi akun Anda, seperti daftar tempat atau tujuan pengiriman favorit Anda;</li>
              <li>Melakukan kegiatan internal yang diperlukan untuk menyediakan Layanan pada Aplikasi/Situs, seperti pemecahan masalah software, bug, permasalahan operasional, melakukan analisis data, pengujian, dan penelitian, dan untuk memantau dan menganalisis kecenderungan penggunaan dan aktivitas.</li>
            </ol>
          </li>
          <li>
            Membantu Pengguna pada saat berkomunikasi dengan layanan pelanggan dari Lion Parcel, diantaranya untuk:
            <ol type="a">
              <li>Memeriksa dan mengatasi permasalahan Pengguna;</li>
              <li>Mengarahkan pertanyaan Pengguna kepada petugas layanan pelanggan yang tepat untuk mengatasi permasalahan; dan</li>
              <li>Mengawasi dan memperbaiki tanggapan layanan pelanggan dari Lion Parcel.</li>
            </ol>
          </li>
          <li>
            Menghubungi Pengguna melalui email, surat, telepon, fax, dan lain-lain, termasuk namun tidak terbatas, untuk membantu dan/atau menyelesaikan proses transaksi maupun proses penyelesaian kendala.
          </li>
          <li>
            Melakukan penelitian pasar dan peninjauan kepuasan pelanggan untuk meningkatkan layanan pelanggan kami, pengembangan dan pengujian produk guna meningkatkan keamanan dan keamanan layanan-layanan pada Aplikasi/Situs, serta mengembangkan fitur dan produk baru.
          </li>
          <li>
            Menginformasikan kepada Pengguna terkait produk, layanan, promosi bermanfaat dan program loyalitas yang mungkin Anda dapat gunakan/ nikmati, studi, survei, berita, perkembangan terbaru, acara dan lain-lain, baik melalui Aplikasi/Situs maupun melalui media lainnya. Lion Parcel juga dapat menggunakan informasi tersebut untuk mempromosikan dan memproses kontes dan undian, memberikan hadiah, serta menyajikan iklan dan konten yang relevan tentang Layanan Lion Parcel dan afiliasinya.
          </li>
          <li>
            Melakukan monitoring ataupun investigasi terhadap transaksi-transaksi mencurigakan atau transaksi yang terindikasi mengandung unsur kecurangan atau pelanggaran terhadap Syarat dan Ketentuan atau ketentuan hukum yang berlaku, serta melakukan tindakan-tindakan yang diperlukan sebagai tindak lanjut dari hasil monitoring atau investigasi transaksi tersebut.
          </li>
          <li>
            Melindungi keamanan atau integritas Layanan dan setiap fasilitas atau peralatan yang digunakan untuk membuat Layanan tersedia;
          </li>
          <li>
            Dalam keadaan tertentu, Lion Parcel mungkin perlu untuk menggunakan ataupun mengungkapkan Data Pribadi milik Pengguna untuk tujuan penegakan hukum atau untuk pemenuhan persyaratan hukum dan peraturan yang berlaku, termasuk dalam hal terjadinya sengketa atau proses hukum antara Pengguna dan Lion Parcel.
          </li>
        </ol>
      </li>
      <li>
        <h4>Penyimpanan & Keamanan Data Pribadi</h4>
        <ol type="1">
          <li>Lion Parcel melindungi setiap Data Pribadi Pengguna yang disimpan dalam sistemnya, serta melindungi data tersebut dari akses, penggunaan, modifikasi, pengambilan dan/atau pengungkapan tidak sah dengan menggunakan sejumlah tindakan dan prosedur keamanan, termasuk kata sandi dan PIN Pengguna.</li>
          <li>Data Pribadi Pengguna juga dapat disimpan atau diproses di luar negara oleh pihak yang bekerja untuk Lion Parcel di negara lain, atau oleh penyedia layanan pihak ketiga, vendor, pemasok, mitra, kontraktor, atau afiliasi Lion Parcel. Dalam hal tersebut, Lion Parcel akan memastikan bahwa Data Pribadi tersebut tetap terlindungi sesuai dengan komitmen Lion Parcel dalam Kebijakan Privasi ini.</li>
          <li>Walaupun Lion Parcel telah menggunakan upaya terbaiknya untuk mengamankan dan melindungi Data Pribadi Pengguna, perlu diketahui bahwa pengiriman data melalui Internet tidak pernah sepenuhnya aman. Dengan demikian, Lion Parcel tidak dapat menjamin 100% keamanan data yang disediakan atau dikirimkan kepada Lion Parcel oleh Pengguna dan pemberian informasi oleh Pengguna merupakan risiko yang ditanggung oleh Pengguna sendiri.</li>
          <li>Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Data Pribadi Pengguna disimpan oleh pihak lain, termasuk instansi penyelenggara negara yang berwenang. Dalam hal kami membagikan Data Pribadi Pengguna kepada instansi penyelenggara negara yang berwenang dan/atau instansi lainnya yang dapat ditunjuk oleh pemerintah yang berwenang atau memiliki kerja sama dengan Lion Parcel, Pengguna menyetujui dan mengakui bahwa penyimpanan Data Pribadi Pengguna oleh instansi tersebut akan mengikuti kebijakan penyimpanan data masing-masing instansi tersebut.</li>
        </ol>
      </li>
      <li>
        <h4>Pengungkapan Data Pribadi Pengguna</h4>
        <p>Lion Parcel tidak akan menggunakan Data Pribadi anda selain untuk tujuan yang telah kami beritahukan kepada anda, atau yang mana diizinkan oleh hukum dan peraturan setempat. Kami tidak akan menawarkan, menerbitkan, memberikan, menjual, mengalihkan, mendistribusikan atau meminjamkan Data Pribadi anda dengan pihak lainnya di luar kami untuk tujuan komersial, tanpa meminta persetujuan dari anda, kecuali terhadap hal-hal sebagai berikut:</p>
        <ol type="1">
          <li>Dibutuhkan adanya pengungkapan Data Pribadi Pengguna kepada mitra atau pihak ketiga lain yang membantu Lion Parcel dalam menyajikan Layanan pada Aplikasi/Situs dan memproses segala bentuk aktivitas Pengguna dalam Aplikasi/Situs, termasuk memproses transaksi, verifikasi pembayaran, pengiriman produk, dan lain-lain.</li>
          <li>Dibutuhkan adanya komunikasi antara mitra usaha Lion Parcel (seperti kurir, pembayaran, dan lain-lain) dengan Pengguna dalam hal penyediaan Layanan.</li>
          <li>Lion Parcel dapat menyediakan informasi yang relevan kepada vendor, konsultan, mitra pemasaran, firma riset, atau penyedia layanan sejenis.</li>
          <li>Pengguna menghubungi Lion Parcel melalui media publik seperti aplikasi, situs, dan media sosial dengan komunikasi antara Pengguna dan Lion Parcel mungkin dapat dilihat secara publik.</li>
          <li>Lion Parcel mengungkapkan data Pengguna dalam upaya mematuhi kewajiban hukum dan/atau adanya permintaan yang sah dari aparat penegak hukum.</li>
        </ol>
      </li>
      <li>
        <h4>Cookies/Tracking</h4>
        <p>Lion Parcel menggunakan pihak ketiga untuk mengumpulkan data pribadi Pengguna seperti nama, nomor telepon, info perangkat, geo-location, cookies pada browser, dan lainnya yang dapat mengidentifikasi Pengguna. Data tersebut akan Lion Parcel gunakan untuk pengembangan fitur dan fasilitas di dalam Aplikasi maupun Aplikasi/Situs Lion Parcel.</p>
        <p>Jika pengguna ingin data miliknya terlacak melalui cookies atau pihak ketiga di Aplikasi dan Situs Lion Parcel, pengguna dapat menonaktifkan cookie pada browser yang digunakan atau menonaktifkan fitur lacak pada perangkat handphone yang digunakan.</p>
      </li>
      <li>
        <h4>Pembaruan Kebijakan Privasi</h4>
        <p>Lion Parcel dapat sewaktu-waktu melakukan perubahan atau pembaruan terhadap Kebijakan Privasi ini. Kami menyarankan agar Pengguna membaca dan memahami ketentuan dalam Kebijakan Privasi ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan Aplikasi/Situs maupun Layanan Lion Parcel lainnya, maka Pengguna dianggap menyetujui perubahan-perubahan dalam Kebijakan Privasi ini.</p>
      </li>
      <li>
        <h4>Cara untuk Menghubungi Kami</h4>
        <p>Jika Anda memiliki pertanyaan mengenai Kebijakan Privasi ini atau Anda ingin mendapatkan akses dan/atau melakukan koreksi terhadap Informasi Pribadi Anda, silahkan hubungi ke email <a href="mailto:help@thelionparcel.com">help@thelionparcel.com</a> atau WA CS 0811-1960-0999.</p>
      </li>
    </ol>
  </div>
</template>

<script>
import MetaInfoUseCase from '@/app/metaApi/usecase/metaInfo.js';

export default {
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      metaInfo: '',
    };
  },
  created() {
    this.fetchMetaInfo();
  },
  methods: {
    fetchMetaInfo() {
      MetaInfoUseCase.getMeta('_app_policy') // path tapi / dengan _ ex: /about jadi _about
        .then((resp) => {
          if (!resp.err && resp.data) {
            this.metaInfo = resp.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/style';

.container {
  padding: 50px 2% 0 2%;

  @include for-size(mobile) {
    padding: 30px 2% 0 2%;
  }
}

p {
  margin: 2%;

  @include for-size(mobile) {
    margin: 15px 2%;
  }
}

ol, li {
  margin: 15px;
  padding: 0 10px;

  @include for-size(mobile) {
    margin: 5px;
    padding: 0 5px;
  }
}

.olBold {
  > li::marker {
    font-weight: bold;
  }

  @include for-size(mobile) {
    margin-left: 20px;
  }
}

h4 {
  font-weight: bold;
}
</style>
