<template>
  <div id="base-sk">
    <div class="wrapper-sk">
      <h1><strong>SYARAT DAN KETENTUAN UMUM PENGIRIMAN</strong></h1>
      <br />
      <br />
      <br />
    </div>
    <div>
      <ol class="num">
        <!-- START main list -->
        <li v-for="(c, i) of contents" :key="'title-' + i">
          <strong class="li-title">{{ c.title }}</strong>
          <div class="new-line-bottom" />
          <div
            v-if="!c.data.isList"
            style="font-weight: normal"
            class="new-line-space"
            v-html="c.data.desc"
          />
          <ol
            v-if="c.data.title"
            style="font-weight: normal"
            class="new-line-space"
          >
            <li class="new-line-bottom" v-html="c.data.title" />
          </ol>
          <ul
            v-if="c.data.isList"
            class="sub-main-list"
            style="font-weight: normal"
            :class="c.data.counter"
          >
            <!-- START sub main list -->
            <li v-for="(d, j) in c.data.desc" :key="'desc-' + j">
              <div v-if="!d.isList" class="li-in" v-html="d.desc" />
              <div
                v-if="d.title"
                class="li-in new-line-bottom"
                v-html="d.title"
              />
              <ol
                v-if="d.isList"
                class="new-line-space"
                style="font-weight: normal"
                :class="d.counter"
              >
                <!-- START list of sub main list -->
                <li v-for="(g, k) in d.desc" :key="'inner-' + k">
                  <ol v-if="g.title" style="font-weight: normal">
                    <li class="new-line-bottom li-in" v-html="g.title" />
                  </ol>
                  <ul v-if="g.isList" :class="g.counter">
                    <!-- START list of inner sub main list -->
                    <li
                      v-for="(fc, id) in g.desc"
                      :key="'inner-2-' + id"
                      v-html="fc.desc"
                    />
                    <!-- END list of inner sub main list -->
                  </ul>
                  <div v-if="!g.isList" class="li-in" v-html="g.desc" />
                </li>
                <!-- END list of sub main list -->
              </ol>
            </li>
            <!-- END sub main list -->
          </ul>
        </li>
        <!-- END main list -->
      </ol>
    </div>
  </div>
</template>

<script>
import MetaInfoUseCase from '@/app/metaApi/usecase/metaInfo.js';

export default {
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      metaInfo: '',
      contents: [
        {
          title: 'Pendahuluan',
          data: {
            counter: null,
            isList: false,
            desc:
              '<p>PT Lion Express dengan merk terdaftar Lion Parcel adalah badan usaha penyedia layanan jasa pengiriman paket. Pengertian Lion Parcel termasuk juga seluruh perwakilan Lion Parcel yang telah ditunjuk di masing-masing lokasi sesuai dengan perjanjian kemitraan Lion Parcel.</p>',
          },
        },
        {
          title: 'Ketentuan tentang Surat Tanda Terima',
          data: {
            counter: 'alpha',
            isList: true,
            desc: [
              {
                counter: null,
                isList: false,
                desc:
                  '<p><b>Surat Tanda Terima (STT)</b> adalah bukti transaksi resmi jasa pengiriman Lion Parcel yang memiliki kekuatan hukum dan bersifat mengikat serta menjadi bagian yang tidak terpisahkan dengan perjanjian/ketentuan tertulis lain yang dikeluarkan oleh Lion Parcel dengan Pelanggan.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p><b>Paket</b> adalah semua bentuk barang atau dokumen yang diterima oleh Lion Parcel dari pelanggan untuk dikirimkan ke kota tujuan.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p><b>Pelanggan</b> adalah perorangan atau badan hukum yang menggunakan jasa Lion Parcel untuk pengiriman paket atau dokumen.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p><b>Penerima</b> adalah perorangan atau badan hukum sebagaimana identitas yang disebutkan di alamat tujuan STT dan/atau perwakilan yang menandatangani <i>Delivery Record</i> Lion Parcel sebagai bukti penerimaan barang.</p>',
              },
            ],
          },
        },
        {
          title: 'Ketentuan Pengiriman Barang',
          data: {
            counter: 'alpha',
            isList: true,
            desc: [
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Lion Parcel berkewajiban untuk mengirimkan semua paket yang telah dibayar (biaya transportasi dan setiap biaya tambahan) kecuali, ada perjanjian khusus antara Lion Parcel dengan Pelanggan.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Pelanggan wajib untuk menuliskan nama, tujuan dan alamat penerima secara lengkap dan benar dalam Surat Tanda Terima (STT). Lion Parcel hanya akan mengirimkan paket atau dokumen jika pelanggan menerima syarat dan kondisi yang ditetapkan. Ketentuan berlaku tidak hanya untuk diri sendiri namun juga pihak lain yang memiliki paket.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Pengiriman akan dilaksanakan sesuai dengan nama, tujuan dan alamat penerima yang tertera di dalam Surat Tanda Terima (STT). Pengiriman akan dilakukan dalam dua kali (2x) percobaan pengiriman dengan kaidah proses pengiriman sebagai berikut: pada saat pengiriman pertama tidak berhasil, maka Lion Parcel akan melakukan kontak kepada pengirim dan penerima untuk melakukan percobaan pengiriman kedua. Pada saat pengiriman kedua dilaksanakan tidak berhasil atau gagal, maka Lion Parcel akan menyimpan paket tersebut di kantor Lion Parcel (<i>Hold at Location/HAL</i>), serta melakukan kontak kepada pengirim untuk meminta kepastian pengiriman yang ketiga atau selanjutnya.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Setelah paket disimpan di kantor Lion Parcel (<i>Hold at Location/HAL</i>), bilamana ada pengiriman ke alamat yang berbeda tetapi berada di dalam wilayah Kota yang sama dan tidak masuk ke dalam area terusan (<i>forward area</i>), disebut sebagai “Koreksi Alamat Pengiriman / <i>Address Correction</i>”, maka Lion Parcel tidak akan melakukan penagihan terhadap pengiriman paket tersebut.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Bilamana setelah paket disimpan di kantor Lion Parcel (<i>Hold at Location/HAL</i>), dan pengirim menginstruksikan untuk; melakukan pengiriman kembali ke alamat pengirim, disebut sebagai “Pengiriman kembali / <i>Return Shipment</i>”; atau melakukan pengiriman ke dalam wilayah Kota yang berbeda, disebut sebagai “Koreksi Wilayah Pengiriman / Route Correction”; dan/atau masuk ke dalam area penerusan (<i>forward area</i>); maka Lion Parcel berhak untuk melakukan penagihan terhadap pengiriman paket tersebut.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Pelanggan wajib mematuhi aturan pengiriman paket (atau kargo) lewat udara sesuai dengan Undang- Undang No. 1 tahun 2009 tentang Penerbangan, serta Peraturan Pelaksana yang berlaku. Pelanggan menjamin bahwa isi paket yang dikirimkan <b>sesuai</b> dengan informasi yang dinyatakan oleh pengirim pada STT. Lion Parcel tidak bertanggung jawab terhadap paket yang isinya tidak sesuai dengan keterangan pada STT ini, dan pelanggan <b>sepenuhnya bertanggung jawab</b> terhadap kemungkinan konsekuensi hukum Republik Indonesia yang diakibatkan dari ketidaksesuaian ini.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Pelanggan bertanggung jawab untuk pengepakan paket, termasuk persiapan dokumen dan/atau barang-barang kiriman. Lion Parcel tidak akan bertanggung jawab atas kerugian atau kerusakan yang disebabkan oleh kemasan yang tidak sesuai standar Lion Parcel.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Lion Parcel memiliki hak untuk menahan atau mengubah rute pengiriman ataupun proses penanganan paket demi alasan keselamatan, keamanan atau operasional tanpa ada pemberitahuan sebelumnya ke pelanggan. Paket akan dianggap telah diterima dalam kondisi baik dan lengkap jika tidak ada keluhan pada waktu paket diterima oleh penerima atau perwakilan dengan bukti tanda tangan pada <i>Delivery Record</i>.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Lion Parcel berhak untuk mengambil langkah-langkah penanganan secepatnya, setelah Lion Parcel mengetahui adanya pelanggaran terhadap kondisi sebagaimana disebutkan dalam Syarat dan Ketentuan Umum Pengiriman.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Pelaksanaan pengiriman dilakukan hingga ke lokasi tujuan (<i>door to door</i>) yaitu dilaksanakan sesuai dengan informasi keterangan alamat pengirim dan penerima yang tertera di dalam Surat Tanda Terima (STT).</p>',
              },
            ],
          },
        },
        {
          title: 'Barang yang dilarang dalam pengiriman',
          data: {
            counter: 'alpha',
            isList: true,
            desc: [
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Lion Parcel tidak menerima pengiriman paket untuk kategori barang-barang berbahaya, barang mudah meledak, mudah terbakar, beracun, barang curian, obat-obatan terlarang (narkotika, ganja, morfin atau zat psikotropika lainnya) atau yang dapat merusak barang lainnya yang dapat membahayakan keselamatan dan keamanan penerbangan sipil dan atau moda transportasi lainnya yang dipergunakan Lion Parcel.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Lion Parcel tidak menerima pengiriman paket yang berisi emas, perak, uang logam, perhiasan, binatang atau tanaman hidup, barang antik dan dokumen berharga atau barang berharga lainnya kecuali, barang tersebut telah diatur dan diizinkan dalam perjanjian lain antara Lion Parcel dan Pelanggan.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Lion Parcel tidak bertanggung jawab atas seluruh biaya, klaim yang mungkin timbul atas kerusakan, kehilangan dan/atau tuntutan dari pihak manapun terhadap pengiriman paket yang dilarang sebagaimana diatur pada poin (a) dan (b) Klausul 4 ini.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Lion Parcel berhak atas pemeriksaan namun, tidak diwajibkan untuk memeriksa paket pelanggan demi memastikan kelayakan pengiriman paket sesuai dengan ketentuan operasional kargo, pengurusan Bea Cukai dan instansi terkait serta Standar Operasi Lion Parcel.</p>',
              },
            ],
          },
        },
        {
          title: 'Pengecualian Tanggung Jawab Lion Parcel',
          data: {
            counter: 'alpha',
            isList: true,
            title:
              '<p>Lion Parcel tidak akan dibebankan terhadap klaim, tanggung jawab dan tuntutan dari pihak manapun terhadap:</p>',
            desc: [
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Keterlambatan pengiriman ke tujuan dan kehilangan atau kerusakan yang disebabkan oleh keadaan memaksa (<i>force majeure</i>) yang termasuk. Namun, tidak terbatas pada kerusuhan, bencana alam, perang, pembajakan, kerusakan pesawat, limitasi terhadap <i>load space</i> pesawat yang berkaitan dengan dimensi dan ukuran serta berat atau kondisi lain diluar kemampuan kontrol Lion Parcel.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Semua risiko teknik yang terjadi selama proses pengangkutan yang menyebabkan Paket yang dikirim tidak berfungsi atau berubah fungsinya baik yang menyangkut mesin atau sejenisnya maupun barang-barang elektronik seperti halnya: TV, komputer, Compact Disc, AC, kulkas, Video Player, mesin cuci dan lain-lain.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Kebocoran, kerusakan dan kehilangan Paket yang diakibatkan karakter paket seperti: barang cair, barang pecah belah, cetakan, makanan, buah-buahan, tumbuh-tumbuhan dan lain-lain.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Setiap tindakan penahanan dan atau penghancuran paket oleh badan pemerintah seperti: bea cukai, kepolisian, Jaksa Penuntut Umum.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Penahanan, penyitaan dan pemusnahan terhadap satu jenis paket yang diterima oleh instansi pemerintah terkait (Bea Cukai, Kepolisian, Kejaksaan dan lain sebagainya) sebagai akibat hukum dari keberadaan jenis Paket yang bersangkutan.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Kehilangan kesempatan memperoleh keuntungan akibat dari kehilangan, kerusakan dan keterlambatan penyerahan Paket.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Apabila terjadi kegagalan pengiriman yang disebabkan karena pelanggaran klausul 3 dan 4 di atas oleh pelanggan, maka biaya pengiriman tidak dapat dikembalikan dan biaya retur serta biaya-biaya lain yang berkaitan dengan kegagalan pengiriman ini menjadi tanggungan pelanggan.</p>',
              },
            ],
          },
        },
        {
          title: 'Ganti Rugi dan Ketentuan Klaim atas Ganti Rugi',
          data: {
            counter: 'alpha',
            isList: true,
            desc: [
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Lion Parcel bertanggung jawab untuk mengganti kerugian yang dialami pelanggan akibat kerusakan atau kehilangan dari pengiriman paket oleh Lion Parcel sepanjang kerugian tersebut terjadi ketika paket masih berada dalam pengawasan Lion Parcel, dengan catatan bahwa kerusakan tersebut semata-mata disebabkan karena kelalaian dan/atau kesalahan dari pihak Lion Parcel.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Jika terjadi kehilangan atau kerusakan atas paket atau dokumen yang tidak menggunakan asuransi pengiriman barang maka kompensasi ganti rugi diberikan berdasarkan Peraturan Pemerintah No. 15 Tahun 2013 tentang Pelaksanaan Undang-Undang Nomor 38 Tahun 2009 tentang Pos, yaitu batas maksimum penggantian sebesar sepuluh kali (10 X) ongkos kirim yang tercantum didalam Surat Tanda Terima (STT) atas paket atau dokumen tersebut.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Untuk nilai barang (harga barang) diatas Rp2.000.000 (<i>Dua Juta Rupiah</i>) dan/atau diatas sepuluh kali (10X) ongkos kirim, pelanggan wajib mengasuransikan barang kiriman melalui perusahaan asuransi atau asuransi yang bekerjasama dengan Lion Parcel.</p>',
              },
              {
                counter: 'roman',
                isList: true,
                title:
                  '<p>Ganti rugi pengiriman menggunakan asuransi yang bekerjasama dengan Lion Parcel mengikuti <i>cover note</i> dari pihak asuransi, dengan keterangan sebagai berikut :</p>',
                desc: [
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Biaya asuransi sebesar 0.4% dari total nilai yang diasuransikan dan diberikan pemberitahuan kepada Lion Parcel untuk diasuransikan sehingga terdapat catatan/note/tick di dalam Surat Tanda Terima (STT) Lion Parcel.</p>',
                  },
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Setiap klaim terhadap kerugian akibat kerusakan dan kehilangan untuk pengiriman dengan menggunakan asuransi harus disampaikan secara tertulis dan diterima oleh Lion Parcel paling lambat 14 (empat belas) hari kalender terhitung dari batas waktu estimasi pengiriman dari proses <i>booking</i> (BKD).</p>',
                  },
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Total batas penggantian klaim yang diajukan sebesar Rp1.000.000.000,00 (Satu Milyar Rupiah) per satu pengiriman dengan pembuktian nilai invoice atas pengiriman tersebut; maksimum Rp500.000.000,00 (Lima Ratus Juta Rupiah) per pengiriman untuk perangkat elektronik.</p>',
                  },
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Total batas penggantian dokumen berharga yang ditunjukan dengan deklarasi akan diganti sebesar data lampiran yang disediakan dari pihak asuransi.</p>',
                  },
                ],
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Setiap klaim terhadap kompensasi kerugian akibat kerusakan dan kehilangan terhadap kewajiban dan tanggung jawab Lion Parcel harus disampaikan secara tertulis dan diterima oleh Lion Parcel paling lambat 7 (tujuh) hari kalender setelah tanggal paket diterima (POD). Sedangkan, barang yang tidak memiliki status POD berlaku ketentuan selama 30 (tiga puluh) hari kalender terhitung dari batas waktu estimasi pengiriman dari proses booking (BKD) berdasarkan produk yang digunakan, yang mana kelengkapan syarat pengajuan klaim maksimal ditunggu 7 (tujuh) hari kerja setelah tanggal masuk pelaporan klaim.</p>',
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Setiap klaim hanya bisa dilakukan oleh pengirim barang dan diajukan ke bagian klaim Lion Parcel. Klaim yang diajukan oleh selain pengirim barang tidak akan di proses oleh Lion Parcel, kecuali atas sepengetahuan dari pengirim dengan melampirkan surat kuasa bermeterai Rp10.000 (sepuluh ribu rupiah) dan dibubuhkan tandatangan basah sebagai bukti bahwa pengirim barang mengetahui paket tersebut sedang dalam proses klaim.</p>',
              },
              {
                counter: 'roman',
                isList: true,
                title:
                  '<p>Semua proses klaim hanya dapat diselesaikan di kantor Lion Parcel kota asal pengiriman dan wajib melampirkan syarat pengajuan klaim sebagai berikut:</p>',
                desc: [
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Berita Acara yang ditandatangani oleh pihak penerima dan Karyawan Lion Parcel di tujuan.</p>',
                  },
                  {
                    counter: 'square',
                    isList: true,
                    title: '<p>Dokumen-dokumen pendukung, antara lain:</p>',
                    desc: [
                      {
                        counter: null,
                        isList: false,
                        desc: '<p>Surat Klaim dari Pengirim;</p>',
                      },
                      {
                        counter: null,
                        isList: false,
                        desc: '<p>Copy Asli STT;</p>',
                      },
                      {
                        counter: null,
                        isList: false,
                        desc:
                          '<p>Packing List isi barang yang dinyatakan dalam STT (bila ada);</p>',
                      },
                      {
                        counter: null,
                        isList: false,
                        desc:
                          '<p>Bukti asli pembelian barang (bila ada); dan</p>',
                      },
                      {
                        counter: null,
                        isList: false,
                        desc: '<p>Foto barang jika rusak.</p>',
                      },
                    ],
                  },
                ],
              },
              {
                counter: null,
                isList: false,
                desc:
                  '<p>Setiap pengajuan klaim <i>Money Back Guarantee</i> (MBG) untuk jenis layanan BOSSPACK, dikhususkan untuk pihak mitra Point of Sales Lion Parcel (POS); hanya dapat mengajukan klaim <i>Money Back Guarantee</i> (MBG) atau keterlambatan jika BOSSPACK masuk kedalam batas waktu penjemputan / pengantaran ke station konsolidator Lion Parcel (<i>origin</i>), atau dalam batas pengantaran (<i>coverage area</i>) kota besar (Zona 1). Biaya kompensasi untuk keterlambatan yang timbul sebesar 1 X ongkos kirim .</p>',
              },
              {
                counter: 'roman', // custom
                isList: true,
                title:
                  '<p>Setiap pengangkutan Paket yang menggunakan layanan Bosspack tidak berlaku klaim <i>Money Back Guarantee</i> (MBG) bilamana :</p>',
                desc: [
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Paket tersebut tidak siap angkut dengan cara yang aman sesuai peraturan penerbangan nasional.</p>',
                  },
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Paket terkena detensi (pengecekan) di bandar udara dikarenakan terdektesi mengandung bahan atau barang yang tidak dapat diangkut menggunakan moda transportasi udara.</p>',
                  },
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Paket yang dikirimkan terkena kontaminasi dari barang-barang lainya sehingga tidak dapat diangkut menggunakan moda transportasi udara.</p>',
                  },
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Ketidaksesuaian deklarasi isi dengan aktual barang.</p>',
                  },
                  {
                    counter: null,
                    isList: false,
                    desc:
                      '<p>Paket mengandung bahan atau barang berbahaya yang tidak dapat diangkut menggunakan pesawat udara.</p>',
                  },
                ],
              },
            ],
          },
        },
        {
          title: 'Penutup',
          data: {
            counter: null,
            isList: false,
            desc:
              '<p>Pelanggan dengan ini menyetujui dan menyatakan membebaskan Lion Parcel dari segala ganti rugi dan tuntutan hukum, baik dari pihak yang mengatasnamakan pelanggan atau tuntutan dari Pihak Ketiga yang diakibatkan dari pelanggaran terhadap Syarat dan Ketentuan Umum Pengiriman. </p>',
          },
        },
      ],
    };
  },
  created() {
    this.fetchMetaInfo();
  },
  methods: {
    fetchMetaInfo() {
      MetaInfoUseCase.getMeta('_sk') // path tapi / dengan _ ex: /about jadi _about
        .then(resp => {
          if (!resp.err && resp.data) {
            this.metaInfo = resp.data;
          }
        })
        .catch(err => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/style';

.wrapper-sk {
  padding-top: 6%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @include for-size(mobile) {
    padding-top: 12%;
    text-align: center;
  }
}

#base-sk {
  padding: 0 5%;
  @include for-size(mobile) {
    padding: 0 8%;
  }
}

.li-title {
  margin-left: 0.6%;
}

.new-line-bottom {
  margin-bottom: 1%;
  @include for-size(mobile) {
    margin-bottom: 3%;
  }
}

.new-line-space {
  margin: 0 0 0 0.6%;

  &__2 {
    margin: 0 0 0 1.3%;
  }
}

ol {
  .roman {
    font-style: normal;

    > li {
      margin-left: 2%;
      margin-bottom: 1%;
      @include for-size(mobile) {
        margin-bottom: 2%;
      }
      list-style-type: lower-roman;
    }
  }

  .alpha {
    font-style: normal;

    > li {
      margin-left: 2%;
      margin-bottom: 1%;
      @include for-size(mobile) {
        margin-bottom: 2%;
      }
      list-style-type: lower-alpha;
    }
  }
}

ol.num {
  font-weight: bold;

  > li {
    list-style-type: decimal;
    margin-bottom: 2%;
    @include for-size(mobile) {
      margin-bottom: 5%;
    }
  }
}

ul {
  .square {
    > li {
      margin-left: 20px;
      list-style: square;
    }
  }
}

.li-in {
  padding-left: 20px;
}

ol li {
  list-style: none;
  position: relative;
}

@include for-size(mobile) {
  .sub-main-list {
    margin-left: 10px;
  }
}
</style>
